<template>
  <section>
    <h2>Characteristics of Epoxy Resin Metering Pump</h2>
    <p>
      1: Diaphragm metering pump technology of MX series. The metering accuracy
      is as high as 0.1%, which reduces material waste and improves product
      quality and production. It can be fully customized according to customers,
      and easy to operate and maintain. With its high precision, under various
      conditions of temperature, viscosity and pressure, it can ensure stable
      and repeatable flow, and the measurement accuracy is as high as 0.3%.
    </p>
    <p>
      It is stable flow and low output pulsation because its unique design makes
      flow output no pulsation, eliminates unnecessary valves and buffer
      components, simplifies the system, reduces costs and improves performance.
    </p>
    <p>
      2: The control panel contains the communication between the electronic
      touch screen and the PLC operation interface. The control signal
      transmission and reception parts will start and stop the metering system
      according to the requirements of the program. The flow rate and mixing
      ratio can be quickly and easily changed by touch.
    </p>
    <p>3: Flow range: 1 to 100 l/min)</p>
    <p>4: Output pressure: 30 bar) max</p>
    <p>5: Viscosity range: 1-4000 CPS centipoise</p>
    <p>6: Operating temperature: -18~510 degrees Celsius</p>
    <p>
      7: Optional accessories: automatic cleaning, heat preservation device,
      explosion-proof motor, etc.
    </p>
    <p>8: Controlled by static mixer/gluing gun</p>
    <p>9: With alarm signal, automatic shutdown</p>
    <p>10: Optional full automation and manual control</p>
    <p>11: Programmable mixing formula</p>
    <p>12: Any mixing ratio can be controlled by pump</p>
    <h2>Application Range of Epoxy Resin Metering Pump</h2>
    <p>
      Silicone oil, polyurethane, adhesive, colorant, paint, coating, polymer
      extrusion molding, extrusion, etc
    </p>
    <p>Application case of epoxy resin metering pump:</p>
    <p>
      1. Auto surface automatic spraying equipment and automatic color changing
      cleaning equipment
    </p>
    <p>2. Automatic windshield squeezer robot</p>
    <p>3. Multi-point automatic gluing extruder for engine cover</p>
    <p>4. Automatic gluing and the equipment for gearbox housing robot</p>
    <p>5. Engine cover sealing strip</p>
    <p>6. Spraying of the filter sealing strip</p>
    <p>
      7, and many other online precision metering, gluing, spraying production
      line applications
    </p>
    <p>Structure of gear pump of epoxy resin metering pump:</p>
    <p>
      It is composed of three finely ground plate-shaped parts, and the material
      is hardened, which has good wear resistance, corrosion resistance and
      lubrication characteristics.
    </p>
  </section>
</template>
<script>
export default {
  title: "Epoxy Metering Pump",
}
</script>